import {
  LitElement,
  html,
  css,
  TemplateResult,
  unsafeCSS,
  CSSResultArray,
} from 'lit'
import { customElement, property } from 'lit/decorators.js'
import Video from '@/interfaces/Video'
import { BASE_URL } from 'services/api.service'
import { openVideoModal } from 'components/modal'
import errorMessages from 'utils/error-messages-de.json'
import germanDate from 'helpers/germanDate'
import durationConverter from 'helpers/durationConverter'
import tailwindcssStyles from 'styles/tailwind.css?inline'
import { router } from '@/router'
import { consume } from '@lit-labs/context'
import { appContext } from '@/context/appContext'
import AppContext from 'interfaces/AppContext'

import config from '@/config'

@customElement('tailwind-template')
export class TailwindTemplate extends LitElement {
  static styles: CSSResultArray = [
    css`
      :host {
        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Regular.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Regular.woff') format('woff');
          font-weight: 400;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Bold.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Bold.woff') format('woff');
          font-weight: 700;
          font-style: normal;
        }

        @font-face {
          font-family: 'Gudea';
          src: url('fonts/Gudea/Gudea-Italic.woff2') format('woff2'),
            url('fonts/Gudea/Gudea-Italic.woff') format('woff');
          font-weight: 400;
          font-style: italic;
        }
        font-family: 'Gudea', sans-serif;
      }
      #scrollToTopButton {
        transition: opacity 0.2s ease-in-out;
      }
      .load-more-btn-tailwind {
        width: fit-content;
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        border: 1px solid #2ba6cb;
        color: #2ba6cb;
      }
      .load-more-btn-tailwind:hover {
        border-color: #165366;
        color: #165366;
      }
      .image-container {
        width: 100%;
      }
      .image-container img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
    `,
    unsafeCSS(tailwindcssStyles),
  ]

  // consume context
  @consume({ context: appContext, subscribe: true })
  context!: AppContext

  @property({ type: Array }) videos: Video[] = []
  @property({ type: Boolean, attribute: 'showMoreButton' }) showMoreButton =
    false
  @property({ type: Boolean }) isPlayList = false
  @property({ attribute: false }) handleLoadMoreVideos = () => { }

  @property({ type: Boolean }) latestVideosFirst = false

  descriptionShortener(text: string, num: number = 100): string {
    return text?.length > num ? text.substring(0, num) + ' ...' : text
  }

  getLatestVideo(): number | string {
    const latestVideo: Video[] = this.videos?.sort((a: Video, b: Video) => {
      const dateA: Date = new Date(a.publishedAt)
      const dateB: Date = new Date(b.publishedAt)
      return dateB.getTime() - dateA.getTime()
    })

    return latestVideo[0].id.toString()
  }

  async connectedCallback(): Promise<void> {
    super.connectedCallback()
    const query = router.location.route?.name
    if (query === 'playlist' || query === 'category') {
      this.isPlayList = true
    }
  }

  protected render(): TemplateResult {

    console.log(`isPlayList: ${this.isPlayList}`)
    console.info(`config.USE_YT_API: ${config.USE_YT_API}`)

    return html`
      <div
        class="container lg:mx-auto _mt-4 px-4 lg:px-0 lg:flex justify-center w-full"
      >
        ${!this.videos?.length
        ? html`
              <div class="flex justify-center w-full  items-center mt-10">
                <h3 class="text-gray-700 opacity-50">
                  ${errorMessages.emptyCategory} :(
                </h3>
              </div>
            `
        : html` <div
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-10"
            >
              ${this.videos
            // Es soll keine Sortierung geben. 
            // Die Videos sollen in der Reihenfolge angezeigt werden, 
            // wie sie vom Server kommen.
            ?.sort(
              this.latestVideosFirst
                ? (a, b) =>
                  new Date(
                    this.isPlayList
                      ? b.video.originallyPublishedAt
                      : b.originallyPublishedAt
                  ).getTime() -
                  new Date(
                    this.isPlayList
                      ? a.video.originallyPublishedAt
                      : a.originallyPublishedAt
                  ).getTime()
                : () => 0
            )
            ?.map((video, i: number) =>
              video && video !== null && video.id !== null
                ? html`
                        <div
                          class="flex justify-center"
                          data-scroll="${i}"
                          title="${this.isPlayList
                    ? config.USE_YT_API ? video.snippet.description : video.video.description
                    : config.USE_YT_API ? video.snippet.description : video.description}"
                        >
                          <div
                            class="rounded-lg shadow-lg bg-white relative flex flex-col justify-between min-w-full"
                          >
                            <div
                              @click="${() =>
                    openVideoModal(
                      this.isPlayList
                        ? config.USE_YT_API ? `${config.YT_EMBED_BASEURL}${video.contentDetails.videoId}` : BASE_URL + video.video.embedPath
                        : config.USE_YT_API ? `${config.YT_EMBED_BASEURL}${video.idYT.videoId}` : BASE_URL + video.embedPath,
                      this.isPlayList
                        ? config.USE_YT_API ? video.snippet.title : video.video.name
                        : config.USE_YT_API ? video.snippet.title : video.name,
                      this.isPlayList
                        ? config.USE_YT_API ? video.snippet.description : video.video.description
                        : config.USE_YT_API ? video.snippet.description : video.description
                    )}"
                              class="relative hyphens image-container"
                              data-link="${this.isPlayList
                    ? config.USE_YT_API ? `${config.YT_EMBED_BASEURL}${video.contentDetails.videoId}` : video.video.embedPath
                    : config.USE_YT_API ? `${config.YT_EMBED_BASEURL}${video.idYT?.videoId ?? ''}` : BASE_URL + video.embedPath}"
                            >
                              <img
                                class="w-full h-auto"
                                src="${this.isPlayList
                    ? config.USE_YT_API ? video.snippet.thumbnails.medium.url : BASE_URL + video.video.thumbnailPath
                    : config.USE_YT_API ? video.snippet.thumbnails.medium.url : BASE_URL + video.thumbnailPath}"
                                alt="${this.isPlayList
                    ? config.USE_YT_API ? video.snippet.title : video.video.name
                    : config.USE_YT_API ? video.snippet.title : video.name}"
                                title="${this.isPlayList
                    ? config.USE_YT_API ? video.snippet.description : video.video.description
                    : config.USE_YT_API ? video.snippet.description : video.description}"
                              />
                              <div
                                @click="${() =>
                    openVideoModal(
                      this.isPlayList
                        ? config.USE_YT_API ? `${config.YT_EMBED_BASEURL}${video.contentDetails.videoId}` : BASE_URL + video.video.embedPath
                        : config.USE_YT_API ? `${config.YT_EMBED_BASEURL}${video.idYT.videoId}` : BASE_URL + video.embedPath,
                      this.isPlayList
                        ? config.USE_YT_API ? video.snippet.title : video.video.name
                        : config.USE_YT_API ? video.snippet.title : video.name,
                      this.isPlayList
                        ? config.USE_YT_API ? video.snippet.description : video.video.description
                        : config.USE_YT_API ? video.snippet.description : video.description
                    )}"
                                class="absolute inset-0 flex items-center justify-center cursor-pointer"
                              >
                                <div
                                  class="bg-gradient-to-t opacity-50 from-gray-900 to-gray-600 w-full h-full"
                                ></div>
                              </div>
                            </div>

                            ${this.context.showBadgeNewVideo &&
                    this.getLatestVideo() === video.id.toString()
                    ? html`<div
                                  class="text-xs absolute top-0 right-0 bg-red-600 px-4 py-2 text-white mt-3 mr-3 transition duration-500 ease-in-out"
                                >
                                  Neu
                                </div>`
                    : ''}

                            <div class="relative -mt-10 p-4 bg-white m-4">
                              <div
                                @click="${() =>
                    openVideoModal(
                      this.isPlayList
                        ? config.USE_YT_API ? `${config.YT_EMBED_BASEURL}${video.contentDetails.videoId}` : BASE_URL + video.video.embedPath
                        : config.USE_YT_API ? `${config.YT_EMBED_BASEURL}${video.idYT?.videoId}` : BASE_URL + video.embedPath,
                      this.isPlayList
                        ? config.USE_YT_API ? video.snippet.title : video.video.name
                        : config.USE_YT_API ? video.snippet.title : video.name,
                      this.isPlayList
                        ? config.USE_YT_API ? video.snippet.description : video.video.description
                        : config.USE_YT_API ? video.snippet.description : video.description
                    )}"
                                class="cursor-pointer"
                                data-scroll="${i}"
                                title="${this.isPlayList
                    ? config.USE_YT_API ? video.snippet.description : video.video.description
                    : config.USE_YT_API ? video.snippet.description : video.description}"
                              >
                                <h5
                                  class="font-semibold text-md hover:text-indigo-600 transition duration-500 ease-in-out inline-block mb-2 hyphens"
                                >
                                  ${this.isPlayList
                    ? config.USE_YT_API ? video.snippet.title : video.video.name
                    : config.USE_YT_API ? video.snippet.title : video.name}
                                </h5>
                              </div>
                              <p class="text-gray-500 text-sm hyphens">
                                ${this.descriptionShortener(
                      this.isPlayList
                        ? config.USE_YT_API ? video.snippet.description : video.video.description
                        : config.USE_YT_API ? video.snippet.description : video.description,
                      200
                    )}
                              </p>
                            </div>
                            <div
                              id="video-metadata"
                              class="m-2 flex-1 flex flex-col justify-end"
                            >
                              <p class="text-gray-500 text-xs pl-2">
                                Erstellt am:
                                ${germanDate(
                      this.isPlayList
                        ? config.USE_YT_API ? video.snippet.publishedAt : video.video.originallyPublishedAt
                        : config.USE_YT_API ? video.snippet.publishedAt : video.originallyPublishedAt
                    )}
                              </p>

                              <p class="text-gray-500 text-xs pl-2 ${config.USE_YT_API ? 'hidden' : ''}">
                                Länge:
                                ${durationConverter(
                      this.isPlayList
                        ? config.USE_YT_API ? '' : video.video.duration
                        : config.USE_YT_API ? '' : video.duration
                    )}
                              </p>
                            </div>
                          </div>
                        </div>
                      `
                : html``
            )}
            </div>`}
      </div>
      ${this.showMoreButton
        ? html`
            <div class="flex gap-10 justify-center items-center my-4">
              <button
                class="load-more-btn-tailwind"
                @click="${this.handleLoadMoreVideos}"
              >
                mehr laden ...
              </button>
            </div>
          `
        : ''}
    `
  }
}
